import React, { useState } from "react";
import Title from "./Title";
import { LuPlus } from "react-icons/lu";
import { IoMdClose } from "react-icons/io";

function Faq({ faq }) {
  const [isOpen, setIsOpen] = useState(null);
  const [faqheight, setFaqheight] = useState();
  const handleOpen = (index) => {
    if (isOpen === index) {
      setIsOpen();
    } else {
      setIsOpen(index);
      const totalheight = document.getElementById(`faq${index}`);
      setFaqheight(totalheight?.clientHeight);
    }
  };

  return (
    <div className="sm:py-[60px] py-10">
      <Title
        Title={"Frequently Asked Questions"}
        Description={
          "Connect with your customers wherever they are, on any platform, all through one intuitive interface."
        }
        className=""
      />
      <div className="grid grid-cols-12 mt-10">
        <div className="lg:col-start-2 lg:col-span-10 col-span-12 grid gap-7 ">
          {faq?.map((item, index) => (
            <div key={index} className="bg-gray-100 p-6 rounded-xl">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleOpen(index)}
              >
                <p className="text-xl font-medium">{item.question}</p>
                <div
                  className={`transition-all`}
                  onClick={() => handleOpen(index)}
                >
                  {isOpen === index ? (
                    <IoMdClose size={24} />
                  ) : (
                    <LuPlus size={24} />
                  )}
                </div>
              </div>
              <div
                className={`overflow-hidden transition-all`}
                style={{ height: isOpen === index ? faqheight : 0 }}
              >
                {item.answer && (
                  <p
                    className="text-gray-600 leading-5 pt-3 pr-7"
                    id={`faq${index}`}
                  >
                    {item.answer}
                  </p>
                )}
                {item.ulfaq && (
                  <ul
                    className="list-disc pl-5 space-y-1 text-gray-600 pt-3 pr-7"
                    id={`faq${index}`}
                  >
                    {item.ulfaq.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Faq;
