import React from "react";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { TbExternalLink } from "react-icons/tb";
import Title from "../components/Title";
import pocarry from "../components/poc";
// import { IoSearch } from "react-icons/io5";

function Main() {
  return (
    <div className="flex-auto flex">
      <div className="sm:pb-[60px] pb-10 pt-[60px] max-w-[1432px] mx-auto gap-[40px] px-4 flex flex-col">
        <Title
          Title={
            "Explore Edelta PoC: Innovative AI Solutions for Your Business"
          }
          Description={
            "Our POC projects demonstrate our ability to validate innovative ideas and ensure their feasibility before full-scale implementation. Here are some of our recent POC projects"
          }
        />
        {/* search bar */}
        {/* <div className="flex justify-center">
          <div className="w-full max-w-[688px] border border-gray-200 rounded-[40px] py-2 pl-4 pr-2 bg-slate-200 flex items-center gap-2">
            <input
              type="text"
              placeholder="Search POC"
              id="search"
              className="flex-auto placeholder-textColor bg-transparent focus:outline-none"
            />
            <div className="p-2 rounded-full bg-[#122B6C] text-[20px] cursor-pointer size-10 flex items-center justify-center">
              <IoSearch className="text-white" />
            </div>
          </div>
        </div> */}
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6">
          {pocarry.map((data, index) => (
            <div
              key={index}
              className="shadow-custom-shadow flex flex-col border  border-gray-200 rounded-xl overflow-hidden bg-white hover:shadow-xl transition-all"
            >
              <div className="bg-gray-100">
                <img
                  src={data?.coverImage}
                  alt={data?.title}
                  className="h-[195px] w-full object-cover"
                />
              </div>
              <div className="py-6 px-4 h-full flex flex-col gap-6">
                <div className="space-y-3 h-full">
                  <h3 className="font-medium text-[18px] leading-[24px]">
                    {data?.title}
                  </h3>
                  <p className="text-[14px] leading-[16px] text-gray-500 line-clamp-3">
                    {data?.description}
                  </p>
                </div>
                <div className="grid items-end gap-6">
                  <div>
                    <Link
                      to={`${data?.title
                        .replace(/[^\w\s]/gi, "")
                        .replace(/[\s]/g, "-")
                        .toLowerCase()}`}
                      className="flex space-x-[6px] items-center w-fit hover:underline underline-offset-4 text-blue-600"
                    >
                      <p className="font-medium">See more Details</p>
                      <div className="text-[20px] animate-[arrow_1s_infinite]">
                        <FaChevronRight size={16} />
                      </div>
                    </Link>
                  </div>
                  {data?.pocURL && (
                    <Link
                      target="_blank"
                      to={data.pocURL}
                      className="flex space-x-[6px] justify-center bg-[#FFC104] hover:bg-[#ffaf04] rounded-[28px] transition-all text-base font-medium py-3 px-4 items-center gap-[10px]"
                    >
                      Go To
                      <TbExternalLink className="size-5" />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Main;
