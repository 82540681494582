import React, { useRef, useState } from "react";
import hero from "../images/hero.png";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { useCookies } from "react-cookie";
import Title from "../components/Title";
import { useNavigate } from "react-router-dom";

function Login() {
  const passwordRef = useRef();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [, setCookie] = useCookies(["password"]);
  const navigate = useNavigate();
  const formSubmit = (e) => {
    e.preventDefault();
    if (passwordRef.current.value === process.env.REACT_APP_POC_PASSWORD) {
      const expires = new Date();
      expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
      setCookie("isVerified", true, {
        expires,
      });
      navigate("/");
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <div className="flex-auto flex">
      <div className="sm:pb-[60px] pb-10 pt-[60px] max-w-[1432px] mx-auto gap-[40px] px-4 flex flex-col">
        <Title
          Title={
            "Explore Edelta PoC: Innovative AI Solutions for Your Business"
          }
          Description={
            "Our POC projects demonstrate our ability to validate innovative ideas and ensure their feasibility before full-scale implementation. Here are some of our recent POC projects"
          }
        />
        <div className="py-12 h-full">
          <div className="grid md:grid-cols-2 md:max-w-5xl max-w-[500px] mx-auto gap-[60px] xl:gap-6 items-center px-4 h-full">
            <div>
              <img
                src={hero}
                alt="headerimg "
                className="mx-auto w-[400px] opacity-90"
              />
            </div>
            <div>
              <div className="bg-blue-50/50 border border-blue-200 rounded-lg p-5">
                <h2 className="font-medium md:text-[24px]  text-ThemeColor mb-6">
                  Welcome to{" "}
                  <span className=" whitespace-nowrap">Edelta POC</span>
                </h2>
                <form onSubmit={formSubmit}>
                  <div className="mb-8">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Password
                    </label>
                    <div className="flex-1 gap-2 bg-white border border-gray-300 py-3 px-2 text-gray-900 placeholder:text-gray-400 rounded-lg text-sm w-full flex items-center focus-within:border-gray-600">
                      <input
                        ref={passwordRef}
                        type={show ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        className="bg-transparent w-full outline-none"
                      />
                      <button type="button" onClick={() => setShow(!show)}>
                        {show ? (
                          <MdOutlineVisibilityOff className="size-5 text-gray-500 cursor-pointer" />
                        ) : (
                          <MdOutlineVisibility className="size-5 text-gray-500 cursor-pointer" />
                        )}
                      </button>
                    </div>
                    {error && (
                      <p className="text-sm text-red-500 mt-1">
                        The Password You Entered is Incorrect. please Try Again
                      </p>
                    )}
                  </div>
                  <input
                    type="submit"
                    value="Submit"
                    className="py-3 px-4 bg-blue-600 hover:bg-blue-800 text-white rounded-lg w-full"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
