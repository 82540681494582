import React from "react";
import { TbExternalLink } from "react-icons/tb";
import CopyTextbtn from "../CopyTextbtn";
import { Link } from "react-router-dom";

function FormIQ({ gotoLink, imageLink }) {
  return (
    <div className="grid gap-6">
      <div className="rounded-lg overflow-hidden border border-gray-100">
        <img src={imageLink} alt="tyclockupping" />
      </div>
      <Link
        target="_blank"
        to={gotoLink}
        className="flex space-x-[6px] justify-center bg-[#FFC104] hover:bg-[#ffaf04] rounded-[28px] transition-all font-bold py-3 px-4 items-cnter gap-[10px] w-[170px]"
      >
        Go To
        <TbExternalLink className="size-5" />
      </Link>
      <div className="grid gap-[30px]">
        <div>
          <h3 className="font-bold text-xl mb-2">Credentials</h3>
          <div className="flex space-x-2 mb-2 items-center text-gray-500">
            <p className="w-[75px]">Email:</p>
            <p>example@123</p>
            <CopyTextbtn text={"example@gmail.com"} />
          </div>
          <div className="flex space-x-2 items-center text-gray-500">
            <p className="w-[75px]">Password:</p>
            <p>example@123</p>
            <CopyTextbtn text={"example@123"} />
          </div>
        </div>

        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Project Overview</h3>
          <p className="text-gray-500">
            Say goodbye to manual data entry and hello to efficiency. Our Smart
            Forms technology transforms traditional paper forms into digital,
            interactive forms that automate data collection and processing,
            saving you time and reducing errors.
          </p>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Details</h3>
          <p className="text-gray-500">
            Our “Turn Paper Forms into Smart Forms” PoC project is designed to
            modernize and streamline your data collection processes. By
            converting traditional paper forms into digital, interactive smart
            forms, we eliminate the need for manual data entry, reduce errors,
            and enhance efficiency. These smart forms can be easily integrated
            into your existing systems, providing a seamless transition from
            paper to digital.
          </p>
        </div>

        <div className="grid gap-2">
          <h3 className="font-bold text-xl">How to Use</h3>
          <p className="text-gray-500">
            Smart Forms can be used across various industries and applications,
            including customer on boarding, surveys, feedback forms, and more.
            They are accessible via multiple devices, ensuring flexibility and
            convenience for both your team and your customers. The forms are
            designed to be user-friendly, with intuitive interfaces that guide
            users through the data entry process.{" "}
          </p>
          <div>
            <p className="font-semibold text-gray-700 mb-1">
              1 Digital Conversion:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                Scan and digitize your existing paper forms using a high-quality
                scanner.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                Use our Smart Forms software to convert the scanned documents
                into interactive digital forms.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 3: </span>
                Customize the digital forms to include interactive elements such
                as drop-down menus, checkboxes, and auto-fill fields.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700 mb-1">2 Integration:</p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                Integrate the smart forms with your existing data management
                systems (e.g., CRM, ERP).
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                Configure the forms to automatically sync data with your
                systems, ensuring real-time updates.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 3: </span>
                Test the integration to ensure data is accurately captured and
                stored.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700 mb-1">3 Deployment:</p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                Deploy the smart forms across your organization, making them
                accessible to all relevant departments and users.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                Train your team on how to use the smart forms effectively,
                highlighting key features and benefits.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 3: </span>
                Monitor the usage and performance of the smart forms, gathering
                feedback to make continuous improvements.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormIQ;
