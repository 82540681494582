import React from "react";
import { TbExternalLink } from "react-icons/tb";
import CopyTextbtn from "../CopyTextbtn";
import { Link } from "react-router-dom";

function DamageDetection({ gotoLink, imageLink }) {
  return (
    <div className="grid gap-6">
      <div className="rounded-lg overflow-hidden border border-gray-100">
        <img src={imageLink} alt="tyclockupping" />
      </div>
      <Link
        target="_blank"
        to={gotoLink}
        className="flex space-x-[6px] justify-center bg-[#FFC104] hover:bg-[#ffaf04] rounded-[28px] transition-all font-bold py-3 px-4 items-cnter gap-[10px] w-[170px]"
      >
        Go To
        <TbExternalLink className="size-5" />
      </Link>
      <div className="grid gap-[30px]">
        <div>
          <h3 className="font-bold text-xl mb-2">Credentials</h3>
          <div className="flex space-x-2 mb-2 items-center text-gray-500">
            <p className="w-[75px]">Email:</p>
            <p>example@123</p>
            <CopyTextbtn text={"example@gmail.com"} />
          </div>
          <div className="flex space-x-2 items-center text-gray-500">
            <p className="w-[75px]">Password:</p>
            <p>example@123</p>
            <CopyTextbtn text={"example@123"} />
          </div>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Project Overview</h3>
          <p className="text-gray-500">
            The Damage Detection Platform leverages cutting-edge AI technology
            to assist users in identifying and analysing damage to their
            furniture. This platform provides a user-friendly interface where
            customers can upload images of their furniture, and our AI-powered
            system analyses the image to detect various types of damage. With
            integration from leading AI platforms like OpenAI, Gemini, and
            Claude, our solution delivers an accurate and comprehensive damage
            overview.
          </p>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Details</h3>
          <p className="text-gray-500">
            Our platform is designed for anyone who wants to quickly and
            efficiently detect damage to their furniture. By uploading an image,
            users can receive valuable insights about the furniture's material,
            colour, and most importantly, the type and extent of damage. This
            multi-platform approach provides detailed analyses from three
            powerful AI systems to ensure precise detection and categorization.
          </p>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Key Attributes Detected</h3>
          <ul className="list-disc pl-5 space-y-2 text-gray-500">
            <li>Furniture Material</li>
            <li>Colour</li>
            <li>
              Damage Category (Ex. External Damage, Stain, Specific Damage like
              Color Loss, Scratches, Peeling){" "}
            </li>
            <li>Damage Area (Ex. Drawer, Frame, Top)</li>
            <li>Damage Timing (When did you notice the damage?)</li>
            <li>Cause of Damage (How did it happen?)</li>
            <li>User Notes (Additional comments or descriptions)</li>
          </ul>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Features</h3>
          <ul className="list-disc pl-5 space-y-2 text-gray-500">
            <li>
              <span className="font-semibold text-gray-700">
                AI-Powered Damage Detection:{" "}
              </span>
              Utilize the capabilities of OpenAI, Gemini, and Claude to get
              multi-dimensional insights.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Comprehensive Reports:{" "}
              </span>
              Receive a detailed report that covers the material, color, damage
              category, area of damage, and more.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Multiple Damage Categories:{" "}
              </span>
              From scratches and peeling to stains and color loss, the platform
              categorizes different types of damage for better understanding.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                User-Friendly Interface:{" "}
              </span>
              Simple, intuitive platform that requires just an image upload and
              a few clicks.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Quick and Accurate Analysis:{" "}
              </span>
              Save time with our fast, AI-powered results, giving you detailed
              information in seconds.
            </li>
          </ul>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">How to Use</h3>
          <div>
            <p className="font-semibold text-gray-700">1: Upload an Image</p>
            <p className="text-gray-500">
              Take a clear photo of the damaged furniture and upload it to our
              platform.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">2: Click on 'Detect'</p>
            <p className="text-gray-500">
              Once the image is uploaded, click the "Detect" button.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              3: Receive Detailed Insights
            </p>
            <p className="text-gray-500">
              In a few moments, you’ll receive a comprehensive overview from
              three AI platforms (OpenAI, Gemini, and Claude), including:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>The material and color of your furniture </li>
              <li>
                Damage category and specific damage type (e.g., external
                scratches, color loss){" "}
              </li>
              <li>
                The area where the damage occurred (e.g., drawer, frame, top)
              </li>
              <li>
                Any additional details like when and how the damage occurred,
                based on your input.{" "}
              </li>
            </ul>
          </div>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Steps to Get Started</h3>
          <div>
            <p className="font-semibold text-gray-700">1: Create an Account</p>
            <p className="text-gray-500">
              Sign up to access the platform and save your damage reports.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">2: Upload Your Image</p>
            <p className="text-gray-500">
              Use the upload button to provide an image of your damaged
              furniture.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">3: Get Your Analysis</p>
            <p className="text-gray-500">
              Within seconds, receive a detailed breakdown of the damage and
              actionable insights.{" "}
            </p>
          </div>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Why Choose Damage Detection?</h3>
          <ul className="list-disc pl-5 space-y-3 text-gray-500">
            <li>
              <span className="font-semibold text-gray-700">
                Multi-Platform Accuracy:
              </span>{" "}
              Our integration with OpenAI, Gemini, and Claude ensures that you
              receive the most accurate and detailed analysis.
            </li>
            <li>
              <span className="font-semibold text-gray-700">Time saving:</span>{" "}
              No need to manually inspect the damage. Let AI do the work for you
              in just a few clicks.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Comprehensive and Detailed:
              </span>{" "}
              From materials to damage types and areas, we provide an all-in-one
              solution to furniture damage detection.
            </li>
            <li>
              <span className="font-semibold text-gray-700">Easy to Use:</span>{" "}
              Simple interface designed for anyone to use, regardless of
              technical expertise.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Real-Time Results:
              </span>{" "}
              Get results instantly without any delays.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DamageDetection;
