import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TbCheck, TbCopy } from "react-icons/tb";

function CopyTextbtn({ text }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const showtooltip = () => {
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };
  return (
    <CopyToClipboard text={text} onCopy={showtooltip}>
      <div className="p-1.5 rounded-md bg-gray-100 hover:bg-gray-300 text-gray-500 hover:text-gray-800 cursor-pointer">
        {showTooltip ? <TbCheck size={16} /> : <TbCopy size={16} />}
      </div>
    </CopyToClipboard>
  );
}

export default CopyTextbtn;
