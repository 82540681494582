import React from "react";
import { TbExternalLink } from "react-icons/tb";
import CopyTextbtn from "../CopyTextbtn";
import { Link } from "react-router-dom";

function TalkingAvatarAI({ gotoLink, imageLink }) {
  return (
    <div className="grid gap-6">
      <div className="rounded-lg overflow-hidden border border-gray-100">
        <img src={imageLink} alt="tyclockupping" />
      </div>
      <Link
        target="_blank"
        to={gotoLink}
        className="flex space-x-[6px] justify-center bg-[#FFC104] hover:bg-[#ffaf04] rounded-[28px] transition-all font-bold py-3 px-4 items-cnter gap-[10px] w-[170px]"
      >
        Go To
        <TbExternalLink className="size-5" />
      </Link>
      <div className="grid gap-[30px]">
        <div>
          <h3 className="font-bold text-xl mb-2">Credentials</h3>
          <div className="flex space-x-2 mb-2 items-center text-gray-500">
            <p className="w-[75px]">Email:</p>
            <p>example@123</p>
            <CopyTextbtn text={"example@gmail.com"} />
          </div>
          <div className="flex space-x-2 items-center text-gray-500">
            <p className="w-[75px]">Password:</p>
            <p>example@123</p>
            <CopyTextbtn text={"example@123"} />
          </div>
        </div>

        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Project Overview</h3>
          <p className="text-gray-500">
            Bring your brand to life with our AI Interactive Digital Avatars.
            These avatars provide a human-like interface for customer
            interactions, offering personalized assistance and support around
            the clock.
          </p>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Details</h3>
          <p className="text-gray-500">
            Our AI Interactive Digital Avatars are designed to provide a
            human-like interface for customer interactions. These avatars
            leverage advanced AI technologies to offer personalized assistance,
            support, and engagement. They can be integrated into various
            platforms, such as websites, mobile apps, and customer service
            portals, to enhance user experience and streamline communication.
          </p>
        </div>

        <div className="grid gap-2">
          <h3 className="font-bold text-xl">How to Use</h3>
          <p className="text-gray-500">
            AI Interactive Digital Avatars can be used in multiple scenarios,
            including customer support, virtual assistants, and interactive
            guides. They are capable of understanding and responding to user
            queries in real-time, providing a seamless and engaging interaction.
            The avatars can be customized to reflect your brand’s personality
            and tone, ensuring a consistent and professional user experience.
          </p>
          <div>
            <p className="font-semibold text-gray-700 mb-1">
              1 Design and Development:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                Define the purpose and scope of the digital avatar, including
                the specific tasks and interactions it will handle.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                Collaborate with our design team to create a visually appealing
                and brand-aligned avatar.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 3: </span>
                Develop the AI algorithms and natural language processing (NLP)
                capabilities to enable the avatar to understand and respond to
                user queries effectively.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700 mb-1">2 Integration:</p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                Integrate the digital avatar with your existing platforms (e.g.,
                website, mobile app, customer service portal).
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                Configure the avatar to access relevant data and systems,
                ensuring it can provide accurate and timely information.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 3: </span>
                Test the integration to ensure the avatar functions seamlessly
                and provides a positive user experience.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700 mb-1">
              3 Deployment and Optimization:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                Deploy the digital avatar across your chosen platforms, making
                it accessible to your users.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                Monitor the avatar’s performance and gather user feedback to
                identify areas for improvement.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 3: </span>
                Continuously update and refine the AI algorithms and NLP
                capabilities to enhance the avatar’s accuracy and
                responsiveness.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TalkingAvatarAI;
