import React from "react";
import { TbExternalLink } from "react-icons/tb";
import CopyTextbtn from "../CopyTextbtn";
import { Link } from "react-router-dom";

function Interview({ gotoLink, imageLink }) {
  return (
    <div className="grid gap-6">
      <div className="rounded-lg overflow-hidden border border-gray-100">
        <img src={imageLink} alt="interview" />
      </div>
      <Link
        target="_blank"
        to={gotoLink}
        className="flex space-x-[6px] justify-center bg-[#FFC104] hover:bg-[#ffaf04] rounded-[28px] transition-all font-bold py-3 px-4 items-cnter gap-[10px] w-[170px]"
      >
        Go To
        <TbExternalLink className="size-5" />
      </Link>
      <div className="grid gap-[30px]">
        <div>
          <h3 className="font-bold text-xl mb-2">Credentials</h3>
          <div className="flex space-x-2 mb-2 items-center text-gray-500">
            <p className="w-[75px]">Email:</p>
            <p>example@123</p>
            <CopyTextbtn text={"example@gmail.com"} />
          </div>
          <div className="flex space-x-2 items-center text-gray-500">
            <p className="w-[75px]">Password:</p>
            <p>example@123</p>
            <CopyTextbtn text={"example@123"} />
          </div>
        </div>

        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Project Overview</h3>
          <p className="text-gray-500">
            Our innovative Codeshare platform is designed to streamline the
            interview process for companies, facilitate team-based coding
            projects, and provide educational tools for programming instruction.
            By enabling real-time collaboration, this platform redefines how
            businesses conduct technical interviews and how developers engage in
            coding tasks, both for team projects and educational purposes.
          </p>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Key Features</h3>
          <div>
            <p className="font-semibold text-gray-700">
              1 Collaborative Coding Environment
            </p>
            <ul className="list-disc pl-5 space-y-1 text-gray-500">
              <li>
                Open a codeshare editor to write or copy code and share it with
                colleagues, friends, or team members.
              </li>
              <li>
                Pair program, troubleshoot, and collaborate in real time, making
                problem-solving more efficient.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              2 Remote Developer Interviews
            </p>
            <ul className="list-disc pl-5 space-y-1 text-gray-500">
              <li>
                Set coding tasks and observe candidates as they work on
                challenges in real-time.
              </li>
              <li>
                Conduct remote or in-person technical interviews without the
                need for traditional whiteboards, offering a more practical
                assessment of developer skills.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              2 Educational Programming Tool
            </p>
            <ul className="list-disc pl-5 space-y-1 text-gray-500">
              <li>
                Share code with students and peers, making it easier to teach
                programming concepts
              </li>
              <li>
                Universities and colleges globally use the platform for
                classroom instruction and collaborative learning sessions.
              </li>
            </ul>
          </div>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">How to Use CodeShare Platform</h3>
          <div>
            <p className="font-semibold text-gray-700 mb-1">
              1 Setting Up a Session:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                Create an account on the CodeShare platform by signing up with
                your professional email.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                Open the editor from the dashboard. You’ll see an interactive
                coding space where you can start writing or pasting code.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 3: </span>
                Click the 'Share' button to generate a shareable link to invite
                teammates, students, or interviewees.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700 mb-1">
              2 Conducting a Team Coding Session:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                Start a new coding session by selecting a project or file to
                work on.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                Share the session link with your team members.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 3: </span>
                Collaborate in real-time, write code, and discuss issues as if
                you were in the same room.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700 mb-1">
              3 Interviewing Developers:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                Prepare the coding tasks beforehand, either from existing
                templates or custom challenges.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                During the interview, share the codeshare link with the
                candidate.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 3: </span>
                Observe in real-time as the candidate writes or modifies code.
                Provide feedback and assess problem-solving skills on the spot.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700 mb-1">
              4 Using for Teaching:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                Schedule a session with students or peers.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                Share your code and explain concepts live, giving real-time
                feedback as students work through challenges.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 3: </span>
                Save and share the session to use as learning material or review
                later.
              </li>
            </ul>
          </div>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">
            Why Choose CodeShare for Your Interview and Educational Needs?
          </h3>
          <ul className="list-disc pl-5 space-y-3 text-gray-500">
            <li>
              <span className="font-semibold text-gray-700">
                Efficient Collaboration:
              </span>{" "}
              Improve team productivity by coding together in real-time without
              switching between tools.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Enhanced Interviews:
              </span>{" "}
              Evaluate candidates based on their live coding skills rather than
              theoretical knowledge, ensuring a more accurate assessment.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Education-Friendly:
              </span>{" "}
              Widely used by universities, CodeShare is an essential tool for
              teaching and learning programming interactively.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Remote Flexibility:
              </span>{" "}
              Perfect for remote interviews or team collaborations, breaking
              down geographical barriers while maintaining high standards for
              interaction.
            </li>
          </ul>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">
            Steps to Get Started with Interview it{" "}
          </h3>
          <div>
            <p className="font-semibold text-gray-700">1: Sign Up</p>
            <p className="text-gray-500">
              Create your professional account to access all platform features.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">2: Explore Templates</p>
            <p className="text-gray-500">
              FDiscover predefined coding tasks and interview challenges for
              various technical roles.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              3: Share and Collaborate
            </p>
            <p className="text-gray-500">
              Use the shareable links to invite interviewees, colleagues, or
              students.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              5: Monitor and Analyze
            </p>
            <p className="text-gray-500">
              Track the progress of interviews or collaborative coding sessions,
              giving feedback in real-time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Interview;
