import React from "react";
import { TbExternalLink } from "react-icons/tb";
import CopyTextbtn from "../CopyTextbtn";
import { Link } from "react-router-dom";

function QuickTyping({ gotoLink, imageLink }) {
  return (
    <div className="grid gap-6">
      <div className="rounded-lg overflow-hidden border border-gray-100">
        <img src={imageLink} alt="typing" />
      </div>
      <Link
        target="_blank"
        to={gotoLink}
        className="flex space-x-[6px] justify-center bg-[#FFC104] hover:bg-[#ffaf04] rounded-[28px] transition-all font-bold py-3 px-4 items-cnter gap-[10px] w-[170px]"
      >
        Go To
        <TbExternalLink className="size-5" />
      </Link>
      <div className="grid gap-[30px]">
        <div>
          <h3 className="font-bold text-xl mb-2">Credentials</h3>
          <div className="flex space-x-2 mb-2 items-center text-gray-500">
            <p className="w-[75px]">Email:</p>
            <p>example@123</p>
            <CopyTextbtn text={"example@gmail.com"} />
          </div>
          <div className="flex space-x-2 items-center text-gray-500">
            <p className="w-[75px]">Password:</p>
            <p>example@123</p>
            <CopyTextbtn text={"example@123"} />
          </div>
        </div>

        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Project Overview</h3>
          <p className="text-gray-500">
            Welcome to Quick Typing, an innovative platform designed to
            revolutionize the way you interact with AI. Similar to ChatGPT,
            Gemini, or Copilot, Quick Typing offers seamless conversational
            experiences powered by ChatGPT-4. Whether you’re looking to generate
            content, engage in meaningful conversations, or utilize voice
            search, Quick Typing has you covered.
          </p>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Details</h3>
          <p className="text-gray-500">Platform Name: Quick Typing</p>
          <p className="text-gray-500">Technology: Powered by ChatGPT-4</p>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Features</h3>
          <ul className="list-disc pl-5 space-y-3 text-gray-500">
            <li>
              <span className="font-semibold text-gray-700">
                Conversational AI:{" "}
              </span>
              Engage in natural, human-like conversations with our AI bot.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Custom Prompts:{" "}
              </span>
              Users can create and add their own prompts to guide the AI in
              generating specific content.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Voice Search:{" "}
              </span>
              Interact with the AI using voice commands for a hands-free
              experience.
            </li>
          </ul>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">How to Use Quick Typing </h3>
          <div>
            <p className="font-semibold text-gray-700">1: Sign Up/Login</p>
            <p className="text-gray-500">
              Create an account or log in to your existing account on the Quick
              Typing platform.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              2: Start a Conversation
            </p>
            <p className="text-gray-500">
              Begin chatting with the AI bot by typing your queries or using
              voice commands.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              3: Create Custom Prompts
            </p>
            <p className="text-gray-500">
              Navigate to the ‘Custom Prompts’ section to build and save your
              own prompts. These prompts can be used to instruct the AI on
              generating specific types of content.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">4: Voice Search:</p>
            <p className="text-gray-500">
              Voice Search: Enable the voice search feature and start speaking
              to the AI bot for a more interactive experience.
            </p>
          </div>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Steps to Get Started</h3>
          <div>
            <p className="font-semibold text-gray-700">1: Visit the Website</p>
            <p className="text-gray-500">
              Go to Quick Typing Website and sign up for an account.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              2: Explore the Dashboard
            </p>
            <p className="text-gray-500">
              Familiarize yourself with the user-friendly dashboard where you
              can access all features.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              3: Create Custom Prompts
            </p>
            <ul className="list-disc pl-5 space-y-1 text-gray-500">
              <li>Go to the ‘Custom Prompts’ section</li>
              <li>Click on ‘Create New Prompt’</li>
              <li>Enter your instructions for ChatGPT-4</li>
              <li>Save the prompt for future use</li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700">4: Use Voice Search</p>
            <ul className="list-disc pl-5 space-y-1 text-gray-500">
              <li>Enable the microphone on your device</li>
              <li>Click on the ‘Voice Search’ icon</li>
              <li>Start speaking your queries to the AI bot</li>
            </ul>
          </div>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Why Choose Quick Typing?</h3>
          <ul className="list-disc pl-5 space-y-3 text-gray-500">
            <li>
              <span className="font-semibold text-gray-700">
                Advanced AI Technology:
              </span>{" "}
              Powered by the latest ChatGPT-4, ensuring high-quality and
              accurate responses.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Customization:
              </span>{" "}
              The ability to create and use custom prompts allows for tailored
              interactions.
            </li>
            <li>
              <span className="font-semibold text-gray-700">Versatility:</span>{" "}
              Supports both text and voice interactions, making it versatile and
              accessible.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default QuickTyping;
