import React from "react";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Invalid name")
    .required("First name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9.+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email address"
    )
    .required("Email is required"),
  message: Yup.string().required("Please write a message"),
});

function Contact() {
  return (
    <div className="p-6 bg-gray-100 rounded-2xl grid gap-5">
      <h3 className="pb-5 text-center font-medium text-[32px]">Contact</h3>
      <Formik
        initialValues={{ name: "", email: "", message: "" }}
        onSubmit={(values, { resetForm }) => {
          console.log(values);
          resetForm();
        }}
        validationSchema={validationSchema}
      >
        {({ handleChange, handleBlur, values }) => (
          <Form className="grid gap-5">
            <div className="grid gap-[6px]">
              <p className="font-medium text-base leading-[18px] ">Name:</p>
              <input
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.name}
                name="name"
                className="p-3 border  border-gray-200 rounded-lg"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="error text-red-500 text-sm -mt-1"
              />
            </div>
            <div className="grid gap-[6px]">
              <p className="font-medium text-base leading-[18px] ">Email:</p>
              <input
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.email}
                name="email"
                className="p-3 border  border-gray-200 rounded-lg"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="error text-red-500 text-sm -mt-1"
              />
            </div>
            <div className="grid gap-[6px]">
              <p className="font-medium text-base leading-[18px] ">Message:</p>
              <textarea
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.message}
                name="message"
                className="p-3 border border-gray-200 rounded-lg resize-none h-[100px]"
              />
              <ErrorMessage
                name="message"
                component="div"
                className="error text-red-500 text-sm -mt-1"
              />
            </div>
            <div className="grid justify-center py-5">
              <button
                type="submit"
                className="bg-[#122B6C] hover:bg-[#ffaf04] text-white py-3 w-[240px] rounded-[28px] text-[18px] transition-all hover:text-black"
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Contact;
