import React, { useMemo } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Contact from "../components/Contact";
import Faq from "../components/Faq";
import pocarry from "../components/poc";

function PocDetail() {
  const { pocname } = useParams();
  const currentPOC = useMemo(
    () =>
      pocarry.find(({ title }) =>
        title
          .replace(/[^\w\s]/gi, "")
          .replace(/[\s]/g, "-")
          .toLowerCase()
          .includes(pocname)
      ),
    [pocname]
  );

  const navigate = useNavigate();

  if (currentPOC)
    return (
      <div className="max-w-[1432px] mx-auto px-4">
        <button
          onClick={() => navigate(-1)}
          type="submit"
          className="flex items-center bg-[#EEF0F4] px-4 py-3 rounded-[26px] mt-[60px] leading-5 hover:bg-[#122B6C] hover:text-white transition-all"
        >
          <FaChevronLeft className="mr-2" />
          Back
        </button>
        <h2 className="mt-[38px] text-[32px] font-medium">
          {currentPOC.title}
        </h2>
        <div className="lg:mt-10 mt-5 grid lg:grid-cols-2 grid-cols-1 mx-auto gap-10 lg:gap-6 sm:pb-[60px] pb-10">
          {currentPOC.pocCustomDetailsPage &&
          typeof currentPOC.pocCustomDetailsPage === "function"
            ? currentPOC.pocCustomDetailsPage()
            : null}
          <div>
            <Contact />
          </div>
        </div>
        <Faq faq={currentPOC.faq} />
      </div>
    );

  return <Navigate to="/" replace />;
}

export default PocDetail;
