import { useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import { useEffect } from "react";
import Routes from "./pages/Routes";

function App() {
  return (
    <div className="App font-robot flex flex-col min-h-screen">
      <Scroll />
      <Routes />
      <Footer />
    </div>
  );
}

const Scroll = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default App;
