import React from "react";
import { TbExternalLink } from "react-icons/tb";
import CopyTextbtn from "../CopyTextbtn";
import { Link } from "react-router-dom";

function ClockUp({ gotoLink, imageLink }) {
  return (
    <div className="grid gap-6">
      <div className="rounded-lg overflow-hidden border border-gray-100">
        <img src={imageLink} alt="tyclockupping" />
      </div>
      <Link
        target="_blank"
        to={gotoLink}
        className="flex space-x-[6px] justify-center bg-[#FFC104] hover:bg-[#ffaf04] rounded-[28px] transition-all font-bold py-3 px-4 items-cnter gap-[10px] w-[170px]"
      >
        Go To
        <TbExternalLink className="size-5" />
      </Link>
      <div className="grid gap-[30px]">
        <div>
          <h3 className="font-bold text-xl mb-2">Credentials</h3>
          <div className="flex space-x-2 mb-2 items-center text-gray-500">
            <p className="w-[75px]">Email:</p>
            <p>example@123</p>
            <CopyTextbtn text={"example@gmail.com"} />
          </div>
          <div className="flex space-x-2 items-center text-gray-500">
            <p className="w-[75px]">Password:</p>
            <p>example@123</p>
            <CopyTextbtn text={"example@123"} />
          </div>
        </div>

        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Project Overview</h3>
          <p className="text-gray-500">
            ClockUp is an all-in-one platform designed to help companies manage
            their projects and teams with ease. It simplifies the entire process
            of assigning tasks, tracking project progress, and ensuring seamless
            communication within a single workspace. ClockUp empowers companies
            to stay organized, monitor team productivity, and complete projects
            efficiently, regardless of size.
          </p>
          <p className="text-gray-500">
            With ClockUp, companies can list projects, assign team members,
            track task completion, and even switch between different workspaces
            effortlessly. Whether you’re managing a small team or handling
            large-scale projects, ClockUp makes it easy to stay on top of every
            aspect of your workflow.
          </p>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Key Features</h3>
          <div>
            <p className="font-semibold text-gray-700">
              1 Project Listing and Team Assignment
            </p>
            <ul className="list-disc pl-5 space-y-1 text-gray-500">
              <li>
                Create and list new projects, assign tasks to specific team
                members, and easily track who is responsible for which parts of
                the project.
              </li>
              <li>
                Monitor the progress of assigned tasks to ensure deadlines are
                met.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              2 Task Management & Progress Tracking
            </p>
            <ul className="list-disc pl-5 space-y-1 text-gray-500">
              <li>
                Assign tasks to individual team members with detailed
                descriptions, deadlines, and milestones.
              </li>
              <li>
                Track the progress of each task in real-time, making sure no
                task is overlooked.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700">3 Team Notifications</p>
            <ul className="list-disc pl-5 space-y-1 text-gray-500">
              <li>
                Team members receive instant notifications for new tasks,
                updates, or changes in project requirements, keeping everyone in
                sync.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              4 Workspace Management
            </p>
            <ul className="list-disc pl-5 space-y-1 text-gray-500">
              <li>
                Create different workspaces for multiple projects or clients,
                allowing you to switch between projects seamlessly.
              </li>
              <li>
                Manage everything in one place without the confusion of juggling
                between different tools or interfaces.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700">5 Time Tracking</p>
            <ul className="list-disc pl-5 space-y-1 text-gray-500">
              <li>
                CMonitor how much time each team member spends on their assigned
                tasks, giving you insights into productivity and workflow
                efficiency.
              </li>
              <li>
                Use this data to better allocate resources and manage deadlines.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              6 Multi-Language Support
            </p>
            <ul className="list-disc pl-5 space-y-1 text-gray-500">
              <li>
                ClockUp supports different programming languages, making it
                versatile for teams working on various technical projects.
              </li>
            </ul>
          </div>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">How to Use ClockUp</h3>
          <div>
            <p className="font-semibold text-gray-700 mb-1">
              1 Setting Up Your Workspace:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                Sign up and log in to the ClockUp platform.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                From the dashboard, create a new project workspace or select an
                existing one. You can choose to create different workspaces for
                different clients or projects.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 3: </span>
                Customize your workspace by adding project details, such as
                project name, description, and deadline.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700 mb-1">
              2 Assigning Tasks and Teams:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                Add your team members to the project by assigning them specific
                roles within the workspace.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                For each task, set clear objectives, descriptions, deadlines,
                and assign the relevant team member to handle the work.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 3: </span>
                Use the "Task Assignment" feature to send notifications to your
                team members, ensuring they’re aware of their responsibilities.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700 mb-1">
              3 Tracking Progress and Time:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                AUse the project dashboard to view ongoing tasks and the overall
                progress of the project.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                For each task, monitor the time spent by each team member to
                complete their work.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 3: </span>
                Check the status of tasks - whether they’re in progress,
                completed, or pending.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700 mb-1">
              4 Switching Between Workspaces:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                If you have multiple projects or clients, create separate
                workspaces for each.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                Switch between workspaces at any time using the workspace
                switcher, making it easy to manage multiple projects without
                losing track of progress.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold text-gray-700 mb-1">
              5 Team Notifications and Collaboration:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">Step 1: </span>
                Enable notifications for your team members so they receive
                real-time updates on task assignments, project changes, or
                deadline shifts.
              </li>
              <li>
                <span className="font-semibold text-gray-700">Step 2: </span>
                Use ClockUp’s integrated chat and comment features to
                communicate with your team within the platform.
              </li>
            </ul>
          </div>
        </div>
        <div className="grid gap-2">
          <h3 className="font-bold text-xl">
            Steps to Get Started with ClockUp
          </h3>
          <div>
            <p className="font-semibold text-gray-700">1: Sign Up</p>
            <p className="text-gray-500">
              Create an account to access ClockUp’s project management features.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              2: Create Your Workspace
            </p>
            <p className="text-gray-500">
              Start by creating a new project workspace or select an existing
              one to begin managing your team and tasks.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              3: Assign and Track Tasks
            </p>
            <p className="text-gray-500">
              Use the task management tools to assign responsibilities and track
              progress in real-time.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">4: Monitor and Adjust</p>
            <p className="text-gray-500">
              Keep an eye on time tracking and adjust team resources as needed
              to stay on target for project deadlines.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              5: Collaborate Seamlessly
            </p>
            <p className="text-gray-500">
              Keep your team notified and connected through ClockUp’s
              communication and notification features.
            </p>
          </div>
          <div className="grid gap-2">
            <h3 className="font-bold text-xl">Benefits of Using ClockUp</h3>
            <ul className="list-disc pl-5 space-y-3 text-gray-500">
              <li>
                <span className="font-semibold text-gray-700">
                  Improved Efficiency:
                </span>{" "}
                With task management, progress tracking, and time monitoring in
                one place, teams can work more efficiently, ensuring projects
                are completed on time.
              </li>
              <li>
                <span className="font-semibold text-gray-700">
                  Easy Collaboration:
                </span>{" "}
                ClockUp enables seamless communication and collaboration between
                team members, making it easier to assign tasks, share updates,
                and work together effectively.
              </li>
              <li>
                <span className="font-semibold text-gray-700">
                  Real-Time Tracking:
                </span>{" "}
                Monitor the progress of your projects and tasks in real-time.
                Know exactly who is working on what and when tasks are being
                completed.
              </li>
              <li>
                <span className="font-semibold text-gray-700">
                  Enhanced Resource Management:
                </span>{" "}
                With time-tracking tools, you can identify where your team’s
                efforts are most effective and allocate resources more
                strategically.
              </li>
              <li>
                <span className="font-semibold text-gray-700">
                  Streamlined Workflow:
                </span>{" "}
                Switch between multiple workspaces for different projects or
                clients effortlessly, making it easy to manage complex
                workflows.
              </li>
              <li>
                <span className="font-semibold text-gray-700">
                  Complete Visibility:
                </span>{" "}
                Track every aspect of the project lifecycle from task assignment
                to completion, giving managers full visibility into team
                productivity and project progress.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClockUp;
