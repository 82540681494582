import React from "react";

function Title({ Title, Description }) {
  return (
    <div className="text-center grid gap-2 max-w-5xl mx-auto">
      <h2 className="font-medium text-ThemeColor md:text-[48px] md:leading-[60px] text-[28px] leading-[40px]">
        {Title}
      </h2>
      <p className="text-base text-[#6B7280]">{Description}</p>
    </div>
  );
}

export default Title;
