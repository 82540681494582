import React from "react";
import { TbExternalLink } from "react-icons/tb";
import CopyTextbtn from "../CopyTextbtn";
import { Link } from "react-router-dom";

function CryptoWallet({ gotoLink, imageLink }) {
  return (
    <div className="grid gap-6">
      <div className="rounded-lg overflow-hidden border border-gray-100">
        <img src={imageLink} alt="interview" />
      </div>
      <Link
        target="_blank"
        to={gotoLink}
        className="flex space-x-[6px] justify-center bg-[#FFC104] hover:bg-[#ffaf04] rounded-[28px] transition-all font-bold py-3 px-4 items-cnter gap-[10px] w-[170px]"
      >
        Go To
        <TbExternalLink className="size-5" />
      </Link>
      <div className="grid gap-[30px]">
        <div>
          <h3 className="font-bold text-xl mb-2">Credentials</h3>
          <div className="flex space-x-2 mb-2 items-center text-gray-500">
            <p className="w-[75px]">Email:</p>
            <p>example@123</p>
            <CopyTextbtn text={"example@gmail.com"} />
          </div>
          <div className="flex space-x-2 items-center text-gray-500">
            <p className="w-[75px]">Password:</p>
            <p>example@123</p>
            <CopyTextbtn text={"example@123"} />
          </div>
        </div>

        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Project Overview</h3>
          <p className="text-gray-500">
            MetaMask allows users to manage their Ethereum accounts directly
            from their browser, simplifying the process of sending, receiving,
            and tracking Ethereum. The platform provides a user-friendly
            interface that integrates blockchain technology, ensuring each
            transaction is secure and transparent. Users can also view real-time
            transaction histories, send Ethereum to other accounts, and check
            their total Ethereum balance at any time. Meta Mask goes a step
            further by enabling users to make purchases using Ethereum, creating
            a versatile ecosystem for both investment and everyday transactions.
          </p>
        </div>

        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Features</h3>
          <ul className="list-disc pl-5 space-y-3 text-gray-500">
            <li>
              <span className="font-semibold text-gray-700">
                Total Ethereum Balance:{" "}
              </span>
              Easily track the total Ethereum balance in your account.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Transaction History:{" "}
              </span>
              View detailed transaction history, including total sent, received,
              and purchased Ethereum.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Send Ethereum:{" "}
              </span>
              ISend Ethereum to any other wallet or account directly from the
              extension.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Receive Ethereum:{" "}
              </span>
              Receive Ethereum from others with a simple wallet address.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Buy Products with Ethereum:{" "}
              </span>
              Purchase items like mobile phones, laptops, and more using your
              Ethereum balance.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Transaction Graph:{" "}
              </span>
              Visualize your transaction history with an interactive graph
              showing sent, received, and purchased Ethereum.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Blockchain Technology:{" "}
              </span>
              Powered by block chain, ensuring the highest level of security and
              transparency.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                All-in-One Platform:{" "}
              </span>
              A complete solution for managing Ethereum and transactions from
              one convenient location.
            </li>
          </ul>
        </div>

        <div className="grid gap-2">
          <h3 className="font-bold text-xl">How to Use</h3>
          <ul className="list-disc pl-5 space-y-1 text-gray-500">
            <li className="text-gray-500">
              Install the Meta Mask Chrome extension from the Chrome Web Store
            </li>
            <li className="text-gray-500">
              Set up your Ethereum wallet or import an existing one using a
              secure seed phrase.
            </li>
            <li className="text-gray-500">
              Once logged in, your total Ethereum balance and transaction
              history will be displayed on the dashboard.
            </li>
            <li className="text-gray-500">
              You can easily send Ethereum by entering the recipient's address
              and the amount you wish to transfer.
            </li>
            <li className="text-gray-500">
              Check your transaction history to review past Ethereum
              transactions and purchases.
            </li>
            <li className="text-gray-500">
              Use your Ethereum to shop for products like mobile phones and
              laptops directly from the platform.
            </li>
            <li className="text-gray-500">
              Analyze your Ethereum transactions using the visual graph, which
              displays all recent activity.
            </li>
          </ul>
        </div>

        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Steps to Get Started</h3>
          <div>
            <p className="font-semibold text-gray-700">1: Install Meta Mask</p>
            <p className="text-gray-500">
              Download the MetaMask Chrome extension from the official store.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              2: Create/Import Wallet
            </p>
            <p className="text-gray-500">
              Set up a new Ethereum wallet or import your existing wallet using
              your seed phrase.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">3: View Dashboard</p>
            <p className="text-gray-500">
              Access the Meta Mask dashboard to check your balance and
              transaction history.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">
              4: Send & Receive Ethereum
            </p>
            <p className="text-gray-500">
              Easily send and receive Ethereum by entering wallet addresses and
              transaction amounts.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">5: Make Purchases</p>
            <p className="text-gray-500">
              Use your Ethereum to purchase products like electronics and more
              through the platform.
            </p>
          </div>
          <div>
            <p className="font-semibold text-gray-700">6: Track Transactions</p>
            <p className="text-gray-500">
              Monitor all your Ethereum activity with a detailed and interactive
              transaction history graph.
            </p>
          </div>
        </div>

        <div className="grid gap-2">
          <h3 className="font-bold text-xl">Why Choose Meta Mask?</h3>
          <ul className="list-disc pl-5 space-y-3 text-gray-500">
            <li>
              <span className="font-semibold text-gray-700">
                Secure & Reliable:
              </span>{" "}
              Built on blockchain technology, Meta Mask offers a highly secure
              platform for managing your Ethereum assets.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                All-in-One Solution:
              </span>{" "}
              A single platform that allows users to send, receive, track, and
              use Ethereum for purchases.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                User-Friendly Interface:
              </span>{" "}
              Intuitive design makes it easy for users, regardless of technical
              expertise, to manage their Ethereum.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Comprehensive Tracking:
              </span>{" "}
              Visualize your transaction history with easy-to-understand graphs
              and data.
            </li>
            <li>
              <span className="font-semibold text-gray-700">
                Transparency & Trust:
              </span>{" "}
              Every transaction is recorded on the blockchain, providing full
              transparency and trust in every interaction.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CryptoWallet;
