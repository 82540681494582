import { Route, Routes as CoreRoutes } from "react-router-dom";
import Home from "./Home";
import PocDetail from "./PocDetail";
import { useCookies } from "react-cookie";
import Login from "./Login";

export default function Routes() {
  const [cookies] = useCookies(["isVerified"]);
  return (
    <div className="App font-robot flex flex-col min-h-screen">
      <CoreRoutes>
        <Route path="*" element={<Login />} />
        {cookies?.isVerified && <Route path="/" element={<Home />} />}
        {cookies?.isVerified && (
          <Route path="/:pocname" element={<PocDetail />} />
        )}
      </CoreRoutes>
    </div>
  );
}
